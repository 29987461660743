body {
    margin: 0 auto;
    height: 100%;
    width: 100%;
    background: rgb(10, 19, 26) no-repeat fixed;
    overflow: hidden;
    color: #fff;
}

canvas { display: block; }

#container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

#stats {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
}

#inner {
    max-width: 50%;
}

#outer {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    min-height: 100%;
}

#audioTracker {
    width: 100%;
    position: absolute;
    z-index: 5000;
    top: 0;
    right: 0;
    font-size: 12px;
    text-align: right;
    padding: 5px;
    color: #fff;
}

.pointer {
    cursor: pointer;
}

#theEnd {
    font-family: 'Baloo Tammudu 2', cursive;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    display: none;
}

#sig {
    font-size: 64px;
}

a {
    color: #d2dae2;
}
